import styled from 'styled-components';

import { nameOrDefault, treeNodeThumbUrl, absoluteThumbUrl } from '../../../sitemap/utils';

const PageContainer = styled.div`
  background: #737373;
  color: white;
  padding: 2px;
  border-radius: 4px;
  width: 130px;
  z-index: 1300;

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }

  .thumbnail {
    display: block;
    width: 100%;
    min-height: 93px;
    border-radius: 4px;
  }
`;

export const SitemapPageThumb = ({ page, styles, attributes, innerRef }) => {
  const thumbnailUrl = absoluteThumbUrl(page.thumbnail || treeNodeThumbUrl(page.screenshot));
  const name = nameOrDefault(page);

  return (
    <PageContainer ref={innerRef} className="shadow" style={styles} {...attributes}>
      {thumbnailUrl ? <img className="thumbnail" src={thumbnailUrl} alt={name} /> : <div className="thumbnail" />}
    </PageContainer>
  );
};

export default SitemapPageThumb;
