/* global Sitemap, toastr, treeState */
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useShallow } from 'zustand/react/shallow';

import { Modal } from './Modal';
import { SitemapProvider } from '../../contexts/SitemapContext';
import { ImportTitle } from './ImportTitle';
import { useImportStore } from './stores/import';
import { ImportFooter } from './ImportFooter';
import { ImportContent } from './ImportContent';
import { ImportingOverlay } from './ImportingOverlay';

const queryClient = new QueryClient();

function useModalState() {
  const { isOpen, close, open } = useImportStore(
    useShallow((state) => ({ isOpen: state.isOpen, close: state.close, open: state.open }))
  );

  useEffect(() => {
    const handleOpenImportPages = () => {
      if (!Sitemap.editionGuard.check()) {
        return;
      }

      if (!treeState.isOrganizedByPath) {
        toastr.error('Sorry, we only support importing pages on the organized by directory view');
        return;
      }

      open();

      // Close any page content editor. Import modal and page editor modal have
      // the same z-index. Building a multi-stack / multi-layer component is not trivial.
      // This is a compromise.
      document.dispatchEvent(new CustomEvent('page/EditContent:close'));
    };

    document.addEventListener('sitemap/ImportPages', handleOpenImportPages);

    return () => {
      document.removeEventListener('sitemap/ImportPages', handleOpenImportPages);
    };
  }, [open]);

  // Register different events that require closing the modal
  useEffect(() => {
    // Close the modal when screenshot slider is opened. Stack modals is not trivial. This is a compromise.
    document.addEventListener('sitemap/ImportPages:close', close);
    // Close the modal when page content editor is opened. Stack modals is not trivial. This is a compromise.
    document.addEventListener('page/EditContent', close);
    // We can't keep import modal open when user clicks on a comment from comments list.
    // The editor goes over the page screenshot modal. The change needed to stack modals is far from trivial.
    // This is a compromise solution.
    document.addEventListener('openComment', close);

    return () => {
      document.removeEventListener('sitemap/ImportPages:close', close);
      document.removeEventListener('page/EditContent', close);
      document.removeEventListener('openComment', close);
    };
  }, [close]);

  return [isOpen, close];
}

function useImportingState() {
  const [isImporting, finishImport] = useImportStore(useShallow((state) => [state.isImporting, state.finishImport]));

  useEffect(() => {
    const handleFinish = (e) => {
      const success = Boolean(e.detail?.success);

      if (!success) {
        toastr.error("We couldn't import the pages. Try again later");
      }

      finishImport(success);
    };

    document.addEventListener('sitemap/ImportPages:finish', handleFinish);

    return () => {
      document.removeEventListener('sitemap/ImportPages:finish', handleFinish);
    };
  }, [finishImport]);

  return [isImporting];
}

function AppProviders({ sitemapId, children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SitemapProvider sitemapId={sitemapId}>{children}</SitemapProvider>
    </QueryClientProvider>
  );
}

export default function ImportPagesModal({ sitemapId }) {
  const [isOpen, close] = useModalState();

  const [isImporting] = useImportingState();

  return (
    <AppProviders sitemapId={sitemapId}>
      <Modal open={isOpen} title={isOpen && <ImportTitle />} onClose={close} footer={<ImportFooter />}>
        {isImporting && <ImportingOverlay />}
        {isOpen && <ImportContent />}
      </Modal>
      {isOpen && <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-right" />}
    </AppProviders>
  );
}
