import styled from 'styled-components';

import { nameOrDefault, treeNodeThumbUrl, absoluteThumbUrl } from '../../../../sitemap/utils';

const PageContainer = styled.div`
  background: #737373;
  color: white;
  padding: 2px;
  border-radius: 4px;
  width: 130px;
  z-index: 1070;

  &[data-popper-reference-hidden='true'] {
    visibility: hidden;
    pointer-events: none;
  }

  .thumbnail {
    display: block;
    width: 100%;
    min-height: 93px;
    border-radius: 4px;
  }

  .title {
    margin-top: 0.3rem;
    font-size: 0.625rem;
    line-height: 1.1;
  }
`;

export const SitemapPageThumb = ({ page, maxChars = 150, styles, attributes, innerRef }) => {
  const thumbnailUrl = absoluteThumbUrl(page.thumbnail || treeNodeThumbUrl(page.screenshot));
  const name = nameOrDefault(page);

  const title = name.length < maxChars ? name : `${name.slice(0, maxChars - 1)}…`;

  return (
    <PageContainer ref={innerRef} className="shadow" style={styles} {...attributes}>
      {thumbnailUrl ? <img className="thumbnail" src={thumbnailUrl} alt={name} /> : <div className="thumbnail" />}
      <div className="title">{title}</div>
    </PageContainer>
  );
};

export default SitemapPageThumb;
