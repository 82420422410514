import { nameOrDefault, nodesToTree, parentPresent } from '../../sitemap/utils';
import { getChildren, visit } from '../../sitemap/tree-renderer/hierarchy';

export function sitemapLabel(sitemap) {
  return `${sitemap.nickname || sitemap.url} - v${sitemap.crawl_version}`;
}

export function buildTree(nodes) {
  if (!nodes) {
    return null;
  }

  // Use the same logic we defined to build the sitemap tree.
  return nodesToTree(nodes.filter(parentPresent));
}

/**
 * Group nodes by parent in the directory view.
 * This way we add more context to text search results. Meaning, we can show the parent of each result
 *
 * @param {*} nodes
 */
export function categorizeNodes(nodes) {
  const tree = buildTree(nodes);

  if (!tree) {
    return [];
  }

  const groups = [
    {
      label: 'Root',
      options: [tree.data],
    },
  ];

  visit(tree, (node) => {
    const children = getChildren(node);

    if (!children) {
      return;
    }

    groups.push({
      label: nameOrDefault(node.data),
      options: children.map((c) => c.data),
    });
  });

  return groups;
}
