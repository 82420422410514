import { useState, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Select, { components } from 'react-select';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';

import { SitemapPageThumb } from './SitemapPageThumb';
import { nameOrDefault } from '../../../sitemap/utils';
import { selectTheme } from '../../shared/theme';

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: '0.75rem',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: state.theme.colors.primary,
    borderRadius: 0,
    boxShadow: state.isFocused ? `0px 1px 0px ${state.theme.colors.primary}` : undefined,
    '&:hover': {
      borderColor: state.theme.colors.primary,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    paddingLeft: 0,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    top: undefined,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '1rem',
    lineHeight: 1.1875,
    opacity: state.isDisabled ? 0.5 : 1,
    color: '#CCD9E8',
    backgroundColor: state.isFocused ? state.theme.colors.primary25 : 'transparent',
    whiteSpace: 'nowrap',
    width: '100%',
    position: 'relative',
    ':active': {
      backgroundColor: state.theme.colors.primary50,
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.theme.colors.primary,
    pointerEvents: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral800,
  }),
};

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: fit-content;
`;

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars h-100" {...rest} maxHeight={undefined}>
      <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
    </components.MenuList>
  );
};

const SearchIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search" />
    </components.DropdownIndicator>
  );
};

const IconContainer = styled.div`
  display: inline-block;
  min-width: 1em;
  text-align: center;
`;

const NodeIcon = ({ data }) => {
  const { isFolder } = data;

  const title = isFolder ? 'Directory' : 'Page';

  return (
    <IconContainer title={title}>
      {isFolder ? <i className="far fa-folder" /> : <i className="far fa-file" />}
    </IconContainer>
  );
};

const Checkbox = styled.div`
  display: inline-block;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  border-radius: 4px;
  cursor: pointer;

  ${({ $checked }) =>
    $checked &&
    css`
      background: #02ffaf;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        background: #383838;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        margin: 3px;
        display: block;
      }
    `}
`;

const popperOptions = {
  strategy: 'fixed',
  placement: 'auto',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [5, 0] },
    },
  ],
};

const NodeOption = ({ children, innerRef, ...rest }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions);

  const { data, isFocused, isSelected } = rest;
  const { isFolder } = data;

  useEffect(() => {
    if (innerRef) {
      innerRef(referenceElement);
    }
  }, [innerRef, referenceElement]);

  return (
    <components.Option innerRef={setReferenceElement} {...rest}>
      <div className="d-flex flex-row flex-gap-2 align-items-center">
        <Checkbox $checked={isSelected} />
        <NodeIcon data={data} />
        {!isFolder &&
          isFocused &&
          createPortal(
            <SitemapPageThumb
              page={data}
              innerRef={setPopperElement}
              styles={styles.popper}
              attributes={attributes.popper}
            />,
            document.body
          )}
        {children}
      </div>
    </components.Option>
  );
};

export const selectComponents = {
  IndicatorSeparator: null,
  DropdownIndicator: SearchIndicator,
  MenuList: ThinScrollbarsMenuList,
  Option: NodeOption,
};

export const NodeSelect = ({ nodes, autoFocus, placeholder, emptyMessage, onChange, value }) => {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      options={nodes}
      getOptionLabel={(node) => nameOrDefault(node)}
      getOptionValue={(node) => node.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      onChange={onChange}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      isMulti
      onInputChange={(newInputValue, { action, prevInputValue }) => {
        if (action === 'input-change') {
          return newInputValue;
        }

        return prevInputValue;
      }}
      value={value}
    />
  );
};

export default NodeSelect;
