export const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#00FFAF',
    primary50: 'rgba(162, 162, 162, 0.5)',
    primary25: 'rgba(162, 162, 162, 0.2)',
    neutral0: '#383838',
    neutral50: 'white',
    neutral80: 'white',
    neutral800: '#727272',
  },
});

export const commentsTheme = {
  fontFamily: `Roboto, 'Proxima Nova W01', 'Rubik', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
              'Avenir Next W01', 'Helvetica Neue', Arial, sans-serif;`,
};
