/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';

import { NodeSelect } from './NodeSelector/NodeSelect';
import { useSelectedNodes } from './hooks/useSelectedNodes';
import { useSelectedSitemap } from './hooks/useSelectedSitemap';
import { useSitemapNodes } from './hooks/useSitemapNodes';
import { categorizeNodes } from './utils';

export function NodeSelector() {
  const [selectedSitemap] = useSelectedSitemap();
  const { isFetching, data } = useSitemapNodes(selectedSitemap?.id);
  const [selectedNodes, setSelectedNodes] = useSelectedNodes();

  const groups = useMemo(() => categorizeNodes(data), [data]);

  return (
    <>
      <NodeSelect
        nodes={groups}
        isLoading={isFetching}
        placeholder="Search…"
        emptyMessage="No pages found"
        value={selectedNodes}
        onChange={setSelectedNodes}
      />
      <button
        type="button"
        className="btn btn-link"
        disabled={selectedNodes.length === 0}
        onClick={() => setSelectedNodes([])}
      >
        Deselect all
      </button>
    </>
  );
}
