/* eslint-disable import/prefer-default-export */

import { useShallow } from 'zustand/react/shallow';

import { useSelectedSitemap } from './hooks/useSelectedSitemap';
import { useImportStore, Steps } from './stores/import';
import { sitemapLabel } from './utils';

export function ImportTitle() {
  const [sitemap] = useSelectedSitemap();
  const { step, clearSitemap } = useImportStore(
    useShallow((state) => ({ step: state.step, clearSitemap: state.clearSitemap }))
  );

  const title = step === Steps.CHOOSE_PAGES ? sitemapLabel(sitemap) : null;

  return (
    <h6 className="mb-3 d-flex align-items-baseline">
      <span className="text-white font-weight-bold flex-shrink-0">
        &gt; Importing&nbsp;
        {title && ':'}
      </span>
      {title && (
        <>
          &nbsp;
          <span className="text-info font-weight-bold text-truncate" title={title}>
            {title}
          </span>
          <button type="button" className="btn btn-link" onClick={clearSitemap}>
            (change)
          </button>
        </>
      )}
    </h6>
  );
}
