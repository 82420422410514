import { useCallback, useEffect, useState } from 'react';
import Select, { components, createFilter } from 'react-select';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

import { selectTheme } from '../../shared/theme';
import Spinnner from '../../shared/Spinner';
import { sitemapLabel } from '../utils';
import { SitemapThumb } from './SitemapThumb';

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: '0.75rem',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: state.theme.colors.primary,
    borderRadius: 0,
    boxShadow: state.isFocused ? `0px 1px 0px ${state.theme.colors.primary}` : undefined,
    '&:hover': {
      borderColor: state.theme.colors.primary,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    paddingLeft: 0,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    top: undefined,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '1rem',
    lineHeight: 1.1875,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? state.theme.colors.neutral0 : '#CCD9E8',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.theme.colors.primary,
    pointerEvents: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral800,
  }),
};

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars" {...rest} maxHeight={undefined}>
      {children}
    </components.MenuList>
  );
};

const SpinnerIndicator = (props) => {
  return <Spinnner small className="mr-3" {...props} />;
};

const SearchIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search" />
    </components.DropdownIndicator>
  );
};

const popperOptions = {
  strategy: 'fixed',
  placement: 'left',
  modifiers: [
    {
      name: 'offset',
      options: { offset: [5, 0] },
    },
  ],
};

const SitemapOption = ({ children, innerRef, ...rest }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, popperOptions);

  const { data, isFocused } = rest;

  useEffect(() => {
    if (innerRef) {
      innerRef(referenceElement);
    }
  }, [innerRef, referenceElement]);

  return (
    <components.Option innerRef={setReferenceElement} {...rest}>
      {isFocused &&
        data.thumbnail_url &&
        createPortal(
          <SitemapThumb
            sitemap={data}
            innerRef={setPopperElement}
            styles={styles.popper}
            attributes={attributes.popper}
          />,
          document.body
        )}
      {children}
    </components.Option>
  );
};

export const selectComponents = {
  IndicatorSeparator: null,
  DropdownIndicator: SearchIndicator,
  MenuList: ThinScrollbarsMenuList,
  LoadingIndicator: SpinnerIndicator,
  Option: SitemapOption,
};

const filter = createFilter({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
  matchFrom: 'any',
  stringify: (option) => {
    const { data: sitemap } = option;

    return `${sitemapLabel(sitemap)} ${sitemap.url}`;
  },
});

export function SitemapSelect({
  sitemaps,
  autoFocus,
  placeholder,
  emptyMessage,
  onChange,
  value,
  isLoading,
  inputValue,
  onInputChange,
}) {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      getOptionLabel={sitemapLabel}
      getOptionValue={(sitemap) => sitemap.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      onChange={onChange}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      options={sitemaps}
      isLoading={isLoading}
      defaultValue={value}
      closeMenuOnSelect={false}
      closeMenuOnScroll={false}
      defaultInputValue={inputValue}
      onInputChange={(newInputValue, { action, prevInputValue }) => {
        if (action === 'input-change') {
          onInputChange(newInputValue);
          return newInputValue;
        }

        return prevInputValue;
      }}
      filterOption={filter}
    />
  );
}
