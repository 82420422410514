import { create } from 'zustand';

export const Steps = {
  CHOOSE_SITEMAP: 'CHOOSE_SITEMAP',
  CHOOSE_PAGES: 'CHOOSE_PAGES',
};

export const useImportStore = create((set, get) => ({
  isOpen: false,
  step: Steps.CHOOSE_SITEMAP,
  sitemap: null,
  sitemapSearch: '',
  nodes: [],
  isImporting: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  setSitemap: (sitemap) => set({ sitemap, nodes: [] }),
  chooseSitemap: () => set({ step: Steps.CHOOSE_PAGES }),
  clearSitemap: () => set({ step: Steps.CHOOSE_SITEMAP }),
  setSitemapSearch: (sitemapSearch) => set({ sitemapSearch }),
  setNodes: (nodes) => set({ nodes }),
  startImport: () => {
    // Block the screen
    set({ isImporting: true });
    // Trigger business logic. See app/javascript/sitemap/tree-changes-tracker.js
    const { nodes, sitemap } = get();
    document.dispatchEvent(new CustomEvent('treeNodeImportNodes', { detail: { nodes, sourceId: sitemap?.id } }));
  },
  finishImport: (success) => {
    if (success) {
      // Unlock the screen, reset node selection and Cclose the modal.
      set({ isImporting: false, nodes: [], isOpen: false });
    } else {
      // Unlock the screen.
      set({ isImporting: false });
    }
  },
}));
